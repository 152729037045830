import { Heading, Image, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import mockupSrc from "../../../../img/services/aiPredictor/mockup.png";
import HookCard, { HookType } from "../../../../components/HookCard";

import hook1 from "../../../../img/services/tasktracker/hook1.jpg";
import hook2 from "../../../../img/services/tasktracker/hook2.jpg";
import hook3 from "../../../../img/services/tasktracker/hook3.jpg";
import hook4 from "../../../../img/services/tasktracker/hook4.jpg";
import CTAButton from "../../../../components/CTAButton";
import { Link, useLocation } from "react-router-dom";
import { generateBook } from "../../../../Nav/Navbar";

const hooks: HookType[] = [
  {
    title: "Future Insights",
    caption: (
      <>
        Predict <span>future trends</span> accurately with our AI app. Transform historical data into actionable insights for smarter decisions.
      </>
    ),
    image: hook1,
  },
  {
    title: "Data-Driven Predictions",
    caption: (
      <>
        Use historical data to make precise predictions. Our AI app offers clarity and confidence for <span>future planning.</span>
      </>
    ),
    image: hook2,
  },
  {
    title: "Real-Time Forecasting",
    caption: (
      <>
        Get <span>real-time predictions</span> with our AI app. Turn past data into immediate, actionable insights for quick decisions.
      </>
    ),
    image: hook3,
  },
  {
    title: "Enhanced Accuracy",
    caption: (
      <>Achieve precise forecasts with advanced AI. Leverage historical data to improve <span>decision-making</span> and outcome prediction.</>
    ),
    image: hook4,
  },
];

const DataPredictor = () => {
  const location = useLocation();

  return (
    <VStack w="full" color="white">
      <Heading size="md" letterSpacing={2.2}>
        Predict future outcomes with precision using our AI app, leveraging historical data for accurate and timely insights.
      </Heading>
      <Stack mx={5} align="center" w="full" direction={{ base: "column-reverse", md: "row" }}>
        <CTAButton mx={5} w={{ base: "auto", md: "full" }} as={Link} to={generateBook(location.pathname)} fontSize="2em" h="2em" size="lg" mb={6}>
          Try it Now!
        </CTAButton>
        <Image src={mockupSrc} filter={"drop-shadow(-10px -10px 25px #5a037d37) drop-shadow(10px 10px 25px #1143a037)"} />
      </Stack>
      {hooks.map((hook, index) => (
        <HookCard {...hook} index={index} key={index} />
      ))}
    </VStack>
  );
};

export default DataPredictor;
