import { Box, Card, CardBody, Flex, FormControl, FormLabel, Heading, Input,  Textarea, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import CTAButton from "../../../components/CTAButton";
import FadeInComponent from "../../../components/FadeInComponent";
import { BookingForm, handleSubmit, validateEmail } from "../../Book";
import EmailSentModal from "../../../components/EmailSentModal";

const Ready = () => {
  const { isOpen, onOpen } = useDisclosure();
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState<BookingForm>({
    name: "",
    email: "",
    company: "",
    service: " (Quick Consultation) ",
    remarks: "",
  });

  const handleSubmitButton = () => {
    setLoading(true)
    handleSubmit(details);
    setTimeout(() => {
      onOpen();
    }, 500);
  };

  return (
    <FadeInComponent>
      <EmailSentModal isOpen={isOpen} onClose={() => {}} />
      <Flex id="book" mb={10} px={10} w="full" direction="column" minH="100vh" h="full">
        <Heading
          display={"inline-block"}
          mt={{ base: "4em", md: 0 }}
          mb={10}
          textAlign={{ base: "center", md: "left" }}
          fontSize={{ base: "2em", md: "4em" }}
          id="service"
        >
          Get A{" "}
          <Box fontSize="1.1em" textShadow={"-1px 1px 20px #2a437f;"} bgClip={"text"} bgGradient={"linear(to-t,blue.500,purple.500)"}>
            Free Consultation
          </Box>{" "}
          today!
        </Heading>
        <Heading size="md">Tell us more about your pain points and let us consult with you!</Heading>
        <Card
          mt={5}
          boxShadow={"20px -20px 75px 0px #5400ff30, -20px 20px 75px 0px #c214ff1f;"}
          aspectRatio={5}
          position="relative"
          p={6}
          w="full"
          h="full"
          bgGradient={"radial(gray.200,gray.100)"}
        >
          <CardBody>
            <FormControl mt={3}>
              <FormLabel mt={1}>Name</FormLabel>
              <Input
                type="name"
                name="name"
                borderColor={"blackAlpha.300"}
                placeholder="Name"
                value={details.name}
                onChange={(e) => setDetails((prev) => ({ ...prev, name: e.target.value }))}
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel mt={1}>Email</FormLabel>
              <Input
                type="email"
                name="email"
                borderColor={"blackAlpha.300"}
                placeholder="Email"
                value={details.email}
                onChange={(e) => setDetails((prev) => ({ ...prev, email: e.target.value }))}
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel mt={1}>Company</FormLabel>
              <Input
                name="company"
                borderColor={"blackAlpha.300"}
                placeholder="Company"
                value={details.company}
                onChange={(e) => setDetails((prev) => ({ ...prev, company: e.target.value }))}
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel mt={1}>Tell Us More</FormLabel>
              <Textarea
                name="remarks"
                borderColor={"blackAlpha.300"}
                placeholder="Tell Us More"
                value={details.remarks}
                onChange={(e) => setDetails((prev) => ({ ...prev, remarks: e.target.value }))}
              />
            </FormControl>
            <CTAButton isLoading={loading} isDisabled={!validateEmail(details.email)} w="full" size="lg" onClick={handleSubmitButton}>
              {validateEmail(details.email) ? "Get a Free Consultation!" : "A Valid Email is Required!"}
            </CTAButton>
          </CardBody>
        </Card>
      </Flex>
    </FadeInComponent>
  );
};

// type PopCardType = {
//   image: string;
//   heading: string;
//   href: string;
// };

// const PopCard = (props: PopCardType) => {
//   const [mouseDown, setMouseDown] = useState(false);
//   const { image, heading, href } = props;
//   return (
//     <Card
//       as={Link}
//       to={href}
//       _hover={{ boxShadow: "0px 5px 11px 0px #103660;", transform: "translateY(-2.5px)" }}
//       transition="0.5s all"
//       bgColor={"none"}
//       onMouseEnter={() => setMouseDown(true)}
//       onMouseLeave={() => setMouseDown(false)}
//       borderColor={"blackAlpha.400"}
//       borderWidth={1}
//       rounded="lg"
//     >
//       <CardHeader cursor={"pointer"} position={"relative"}>
//         <Image rounded="lg" aspectRatio={1} objectFit={"cover"} objectPosition={"0.2"} w={{ base: "80vw", md: "20vw" }} src={image} />
//         <Heading
//           fontSize="3em"
//           color="gray.300"
//           textShadow={mouseDown ? "0px 0 10px #0a0f77" : "none"}
//           position={"absolute"}
//           left={0}
//           w="full"
//           textAlign={"center"}
//           top={{ base: "40vw", md: "10vw" }}
//           opacity={mouseDown ? 1 : 0}
//           transition="0.5s all"
//         >
//           {heading}
//         </Heading>
//       </CardHeader>
//     </Card>
//   );
// };

export default Ready;
