import { Heading, Image, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import mockupSrc from "../../../../img/services/tasktracker/mockup.png";
import HookCard, { HookType } from "../../../../components/HookCard";

import hook1 from "../../../../img/services/tasktracker/hook1.jpg";
import hook2 from "../../../../img/services/tasktracker/hook2.jpg";
import hook3 from "../../../../img/services/tasktracker/hook3.jpg";
import hook4 from "../../../../img/services/tasktracker/hook4.jpg";
import CTAButton from "../../../../components/CTAButton";
import { Link, useLocation } from "react-router-dom";
import { generateBook } from "../../../../Nav/Navbar";

const hooks: HookType[] = [
  {
    title: "Boost Productivity with Real-Time Updates",
    caption: (
      <>
        Stay on top of your tasks with <span>instant updates</span> and notifications for sales and job orders, ensuring everyone is always informed.
      </>
    ),
    image: hook1,
  },
  {
    title: "Streamline Your Workflow with Role-Based Access",
    caption: (
      <>
        Enhance <span>security</span> and efficiency by assigning specific roles to team members, allowing <span>tailored access</span> to the
        features they need.
      </>
    ),
    image: hook2,
  },
  {
    title: "Stay on Top of Orders with Organized Tracking",
    caption: <>Manage and track all your sales and job orders in one place, with <span>clear</span> status indicators, priority labels, and deadlines.</>,
    image: hook3,
  },
  {
    title: "Enhance Collaboration Across Teams",
    caption: <>Improve team communication and collaboration with tools that allow comments, file attachments, and discussions directly within each order.</>,
    image: hook4,
  },
];

const TaskTracker = () => {
  const location = useLocation()

  return (
    <VStack w="full" color="white">
      <Heading size="md" letterSpacing={2.2}>
        Real-time sales and job order management with seamless role-based collaboration and instant updates.
      </Heading>
      <Stack mx={5} align='center' w='full' direction={{base:'column',md:'row'}}>
      <Image src={mockupSrc} filter={"drop-shadow(-10px -10px 25px #5a037d37) drop-shadow(10px 10px 25px #1143a037)"} />
      <CTAButton mx={5} w={{base:'auto',md:'full'}} as={Link} to={generateBook(location.pathname)} fontSize='2em' h='2em' size='lg' mb={6}>Try it Now!</CTAButton>
      </Stack>
      {hooks.map((hook, index) => (
        <HookCard {...hook} index={index} key={index} />
      ))}
    </VStack>
  );
};

export default TaskTracker;
