import { Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import CTAButton from "./CTAButton";
import { Link, useLocation, useNavigate } from "react-router-dom";

const EmailSentModal = ({ isOpen, onClose }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Modal
      onOverlayClick={() => {
        location.pathname === "/" ? window.location.reload() : navigate("/");
      }}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent p={10} bgGradient={"linear(to-b,black,gray.800)"}>
        <ModalHeader color="white" as={Heading}>
          Email Sent!
        </ModalHeader>
        <ModalBody>
          <Heading color="white" size="md">
            We will get back to you in 3-5 working days
          </Heading>
          <CTAButton
            onClick={
              location.pathname === "/"
                ? () => {
                    window.location.reload();
                  }
                : undefined
            }
            as={location.pathname === "/" ? undefined : Link}
            to="/"
          >
            Back to Home Page
          </CTAButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailSentModal;
