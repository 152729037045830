import { Divider, Flex, Heading, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import CTAButton from "../../../components/CTAButton";
import { Link } from "react-router-dom";

import cc1 from "../../../img/services/aiPredictor/cc1.jpg";
import cc2 from "../../../img/services/aiPredictor/cc2.jpg";
// import cc3 from "../../../img/services/aiPredictor/cc3.jpg";
import HookCard, { HookType } from "../../../components/HookCard";

const hooks: HookType[] = [
  {
    title: "Predictive Analytics",
    image: cc1,
    caption: (
      <>
        Harness the power of AI to forecast outcomes with <span>unparalleled accuracy</span>. 
        Leverage historical data to predict trends, <span>optimize</span> operations, and
        drive informed decision-making.
      </>
    ),
  },
  {
    title: "Computational Efficiency",
    image: cc2,
    caption: (
      <>
       Our AI <span>accelerates</span> complex simulations, reducing computation times from hours to minutes. 
       Maximize efficiency and <span>minimize resource</span> usage with cutting-edge predictive models.
      </>
    ),
  },
  {
    title: "Simulation Optimization",
    image: cc1,
    caption: (
      <>
         <span>Improve</span> simulation accuracy and speed with AI-driven insights. Utilize historical data to enhance model precision and achieve <span>faster</span>, more reliable results.
      </>
    ),
  },
  {
    title: "Historical Data Insights",
    image: cc2,
    caption: (
      <>
        Unlock <span>valuable</span> insights from your historical data. Our AI analyzes past trends to provide actionable predictions and strategies for future <span>success</span>.  
      </>
    ),
  },
  {
    title: "Advanced Forecasting",
    image: cc1,
    caption: (
      <>
        Achieve unparalleled foresight with our <span>advanced</span> AI forecasting tools. Predict long-term trends and potential challenges with precision and <span>confidence</span>.
      </>
    ),
  },
];

const AIServices = () => {
  return (
    <VStack w="full" spacing={10} h="full" divider={<Divider borderColor={"#525252"} display={{ base: "block", md: "none" }} />}>
      <Flex
        rounded="md"
        w="full"
        mb={{ base: 0, md: 10 }}
        mx={1}
        direction="column"
        p={2}
        backdropFilter={"blur(3px)"}
        // boxShadow={"0 0 50px 2px #353535"}
      >
        <Heading color="gray.400">Our Products</Heading>
        <Stack px={{ base: 5, md: 5 }} mb={{ base: 0, md: 10 }} w="full" my={4} spacing={10} wrap="wrap" direction={{ base: "column", md: "row" }}>
          <CTAButton rounded="md" as={Link} to="/services/ai/data-predictor" mt={0} flex={1} w="full" size="lg" fontSize="2em">
            Predictor
          </CTAButton>
        </Stack>
      </Flex>
      <Heading color="gray.400" mb={4}>
        Why use our AI Products?
      </Heading>
      {hooks.map((hook, index) => (
        <HookCard {...hook} index={index} key={index} />
      ))}
      <CTAButton fontSize="2em" py={10} as={Link} to="/book?service=Cloud Computing">
        Book Now
      </CTAButton>
    </VStack>
  );
};

export default AIServices;
