import { Flex, Heading, Image, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import augmentImage from "../../img/augment-bg-m.png";
// import augmentImage2 from "../../img/augment-bg-2.jpg";
import Specialization from "./Specialization";
import CTAButton from "../../components/CTAButton";
import Ready from "./Ready";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <VStack pt={{ base: "40px", md: "100px" }} id="page" color="white" spacing={2} align="center" w="full" justify={"start"} direction={"column"}>
      <Stack justify={{ base: "start" }} minH="calc(100vh - 200px)" /* h="100vh" */ direction={{ base: "column-reverse", md: "row" }}>
        <Flex mx={10} className="typewriter" color="white" align={"center"} fontSize="x-large" direction={{ base: "column" }}>
          <Heading
            textShadow={"20px -20px 50px #4c02f324, -20px 19px 50px #1d00f92b"}
            pb={2}
            fontWeight={700}
            letterSpacing={0.3}
            fontSize={{ base: "2em", md: "5em" }}
            as={"h1"}
          >
            Augment
          </Heading>
          <Heading size="xl" as={"h4"}>
            Sdn. Bhd.
          </Heading>
          <Heading mt={5} size="md" fontWeight={300}>
            Empowering Businesses with AI Technology
          </Heading>
          <CTAButton size="lg" as={Link} to="/services">
            Find out More
          </CTAButton>
        </Flex>
        <Flex align="start">
          <Image src={augmentImage} w={{ base: "full", md: "50vw" }} h="auto" objectFit={"contain"} />
        </Flex>
      </Stack>
      <Specialization />
      <Ready />
    </VStack>
  );
};

export default HomePage;
