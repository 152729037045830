import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter, useLocation, useOutlet } from "react-router-dom";

import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
// import SoftwareServices from "./Pages/Services/Software";
import HomePage from "./Pages/Index";

import "@fontsource-variable/gabarito";
import ComingSoon from "./Pages/ComingSoon";
import Book from "./Pages/Book";
import { AnimatePresence } from "framer-motion";

import { motion } from "framer-motion";
import Service from "./Pages/Services";
import SoftwareServices from "./Pages/Services/Software";
import TaskTracker from "./Pages/Services/Software/TaskTracker";
import AIServices from "./Pages/Services/AI";
import DataPredictor from "./Pages/Services/AI/Predictor";
// import { Global } from '@emotion/react';

// Fonts
// const Fonts = () => (
//   <Global
//     styles={`
//       /* latin */
//       @font-face {
//         font-family: 'Heading Font Name';
//         font-style: normal;
//         font-weight: 700;
//         font-display: swap;
//         src: url('./fonts/headingfont.woff2') format('woff2'), url('./fonts/headingfont.woff') format('woff');
//         unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//       }
//     `}
//   />
// )

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const AnimatedOutlet: React.FC = () => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return <>{outlet}</>;
};

const RootContainer = () => {
  
  const location = useLocation();
  return (
    <AnimatePresence mode="popLayout">
      <Box w="full" h="full" bgGradient={"linear(to-b,black,gray.900)"}>
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.5,
            y: { type: "inertia" },
          }}
        >
          <AnimatedOutlet />
        </motion.div>
      </Box>
    </AnimatePresence>
  );
};

const router = createBrowserRouter([
  {
    element: <RootContainer />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: "book",
            element: <Book />,
          },
          {
            path: "/services",
            element: <Service />,
            children: [
              {
                path: "cloud-computing",
                children: [
                  { index: true, element: <SoftwareServices /> },
                  { path: "tasktracker", element: <TaskTracker /> },
                ],
              },
              {
                path: "ai",
                children: [
                  { index: true, element: <AIServices /> },
                  { path: "data-predictor", element: <DataPredictor /> },
                ],
              },
            ],
          },
          { path: "*", element: <ComingSoon /> },
        ],
      },
    ],
  },
]);

const RouterApp = () => {
  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={router} />
    </AnimatePresence>
  );
};

const theme = extendTheme({
  fonts: {
    heading: `'Gabarito Variable', system-ui;`,
  },
});

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterApp />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
