import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const CTAButton = React.forwardRef((props: ButtonProps & Record<string, any>, ref) => {
  return (
    <Button
      ref={ref}
      bgGradient={"linear(to-t,purple.600,blue.800)"}
      color="white"
      fontWeight={"bold"}
      px={10}
      py={2}
      rounded="full"
      style={{
        whiteSpace: "normal",
        wordWrap: "break-word",
      }}
      mt={"2.4em"}
      w="fit-content"
      _hover={{ boxShadow: "0 5px 15px rgba(145, 92, 182, .4)", transform: "scale(1.02)" }}
      {...props}
    />
  );
});

export default CTAButton;
