import { Divider, Flex, Heading, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import CTAButton from "../../../components/CTAButton";
import { Link } from "react-router-dom";

import cc1 from "../../../img/services/cc-1.jpg"
import cc2 from "../../../img/services/cc-2.jpg"
import cc3 from "../../../img/services/cc-3.jpg"
import cc4 from "../../../img/services/cc-4a.jpg"
import cc5 from "../../../img/services/cc-5.jpg"
import HookCard, { HookType } from "../../../components/HookCard";


const hooks: HookType[] = [
  {
    title: "Stay Ahead with Our Cloud Services",
    image: cc3,
    caption: (
      <>
        Stay competitive in the <span>digital age</span> with our comprehensive <span>cloud services</span> that offer seamless integration, robust
        data management, and advanced analytics.
      </>
    ),
  },
  {
    title: "Cost-Effective Cloud Computing",
    image: cc5,
    caption: (
      <>
        Reduce your IT <span>expenses</span> and optimize resource allocation with our <span>cost-effective cloud computing</span> solutions, allowing you to
        invest more in your core business.
      </>
    ),
  },
  {
    title: "Expert Support and Consulting",
    image: cc4,
    caption: (
      <>
        Our team of <span>cloud experts</span> is here to support you every step of the way, from planning and migration to optimization and ongoing
        management.
      </>
    ),
  },
  {
    title: "Unlock the Full Potential of Your Data",
    image: cc2,
    caption: (
      <>
        Leverage the power of <span>cloud computing</span> to unlock valuable insights from your data, driving smarter decisions and better{" "}
        <span>business outcomes</span>.
      </>
    ),
  },
  {
    title: "Join the Cloud Revolution",
    image: cc1,
    caption: (
      <>
        Be part of the <span>cloud revolution</span> and take your business to new heights with our advanced <span>cloud computing solutions</span>.
      </>
    ),
  },
];

const SoftwareServices = () => {
  return (
    <VStack w="full" spacing={10} h="full" divider={<Divider borderColor={"#525252"} display={{ base: "block", md: "none" }} />}>
      <Flex
        rounded="md"
        w="full"
        mb={{ base: 0, md: 10 }}
        mx={1}
        direction="column"
        p={2}
        backdropFilter={"blur(3px)"}
        // boxShadow={"0 0 50px 2px #353535"}
      >
        <Heading color="gray.400">Our Products</Heading>
        <Stack px={{ base: 5, md: 5 }} mb={{ base: 0, md: 10 }} w="full" my={4} spacing={10} wrap="wrap" direction={{ base: "column", md: "row" }}>
          <CTAButton rounded='md' as={Link} to="/services/cloud-computing/tasktracker" mt={0} flex={1} w="full" size="lg" fontSize="2em">
            Task Tracker
          </CTAButton>
          <CTAButton rounded='md' as={Link} to="/services/cloud-computing/projectracker" mt={0} flex={1} w="full" size="lg" fontSize="2em">
            Project Tracker
          </CTAButton>
          <CTAButton rounded='md' as={Link} to="/services/cloud-computing/stocktracker" mt={0} flex={1} w="full" size="lg" fontSize="2em">
            Stock Tracker
          </CTAButton>
        </Stack>
      </Flex>
      <Heading color="gray.400" mb={4}>
        Why Cloud Computing With Us?
      </Heading>
      {hooks.map((hook, index) => (
        <HookCard {...hook} index={index} key={index} />
      ))}
      <CTAButton fontSize="2em" py={10} as={Link} to="/book?service=Cloud Computing">
        Book Now
      </CTAButton>
    </VStack>
  );
};



export default SoftwareServices;
