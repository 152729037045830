import React, { useEffect, useState } from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import NavBar from "./Nav/Navbar";
import { Box, IconButton, Image, Tooltip } from "@chakra-ui/react";
import Footer from "./Pages/Index/Footer";
import bgAbstract from "./img/bg-abstract.jpg";
import { FaArrowUp } from "react-icons/fa6";

function App() {
  const [offset, setOffset] = useState(0);
  const container = document.querySelector('.App')
  function updateVhProperty() {
    // First we get the viewport height and we multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    // Update the --vh property on initial load
    updateVhProperty();
    // Update the --vh property on resize
    window.addEventListener("resize", updateVhProperty);

    return () => {
      window.removeEventListener("resize", updateVhProperty);
    };
  }, []);
  const handleScroll = () => {
    const yOffset = container?.scrollTop ?? 0;
    setOffset(yOffset * 0.01); // Adjust the multiplier to control the speed of the parallax effect
  };

  useEffect(() => {
    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [container]) //eslint-disable-line 

  return (
    <Box
      h="calc(var(--vh, 1vh) * 100)"
      overflowY={"auto"}
      overflowX={"hidden"}
      mb={{ base: 5, md: 0 }}
      w="full"
      className="App"
      bgGradient={"linear(to-b,black,gray.800,  gray.900)"}
    >
      <NavBar />
      <Image
        transition={"1s all ease-out"}
        top={`${offset}px`} // Adjust the image position based on scroll
        zIndex={0}
        pointerEvents={"none"}
        opacity={0.1}
        pos="absolute"
        left={0}
        w="full"
        h="full"
        objectFit={"cover"}
        src={bgAbstract}
      />
      <Box minH="100vh" style={{ width: "100%" }} id="container" pos="relative">
        <Tooltip label="Back to Top">
          <IconButton     
            opacity={(container?.scrollTop ?? 0) < 1 ? 0 : 1}
            pointerEvents={(container?.scrollTop ?? 0) < 1 ? 'none' : 'all'}
            display={{base:'none',md:'flex'}}       
            onClick={()=>{
              document.querySelector('.App')?.scrollTo({top:0, behavior:'smooth'})
            }}
            _hover={{
              boxShadow:"0 0 10px 1px #dddddd6d",
              transform:'translateY(-2px)'
            }}
            transition='0.3s ease-out'
            bgGradient={"linear(to-t,white,gray.200)"}
            zIndex={2}
            bottom={"150px"}
            right="2em"
            aria-label="Go Back to Top"
            pos={"fixed"}
            rounded="full"
            icon={<FaArrowUp />}
          />
        </Tooltip>
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
