import { Box, Card, CardBody, CardHeader, Divider, Flex, Heading, Icon, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import highlightProps from "./props/highlightProps";
import { FaWindowRestore } from "react-icons/fa6";


type ProductCardType = { title: string; caption: any; href?: string };

const ServiceCard = ({ title, content, products, href }: { href: string; title: string; content: any; products: ProductCardType[] }) => {
  return (
    <>
      <Card
        backdropFilter={"blur(2px)"}
        as={Link}
        to={href}
        _hover={{
          transform: { base: "none", md: "translateY(-5px)" },
          shadow: { base: "none", md: "0 0 100px 10px #07169a2b,-10px 10px 4px 0px #00000080" },
        }}
        transition={"0.3s all ease-out"}
        maxW="400px"
        boxShadow={"10px 10px 100px 0px #0b438828"}
        bgColor={"#00000035"}
        minW={{ base: "full", md: "23vw" }}
      >
        <CardHeader {...highlightProps} as={Heading}>
          {title} 
        </CardHeader>
        <CardBody>
          <Text minH="140px" color="white">
            {content}
          </Text>
          <Divider display={{ base: "none", md: "block" }} my={4} borderColor="gray.500" />
          <Heading display={{ base: "none", md: "block" }} mb={2} mt={5} textAlign={{ base: "center", md: "left" }} color="gray.300" size="md">
            Product Line
          </Heading>
          <VStack display={{ base: "none", md: "block" }} w="full">
            {products.map((product, i) => (
              <ProductCard {...product} key={i} />
            ))}
          </VStack>
        </CardBody>
      </Card>
    </>
  );
};


const ProductCard = (product: ProductCardType) => {
  const [rotate, setRotate] = useState({ x: 0, y: 0 });
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();

  const handleMouseMove = (e: any) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width - 0.5) * 10; // adjust multiplier for more/less rotation
    const y = ((e.clientY - rect.top) / rect.height - 0.5) * 10;
    setRotate({ x, y });
    if (!isHover) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    setRotate({ x: 0, y: 0 });
    setIsHover(false);
  };

  return (
    <Card
      className="product-card"
      zIndex={1}
      _hover={{
        transform: `translateY(-10px) rotateX(${rotate.y}deg) rotateY(${rotate.x}deg)`,
        boxShadow: "0 0 100px 5px #311ab6a6, -10px 10px 4px 0px #00000080",
      }}
      transition="0.3s all ease-out"
      mb={2}
      cursor="pointer"
      bgGradient={"linear(to-r,blue.700,purple.800)"}
      w="full"
      borderRadius="md"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (product.href) {
          navigate(product.href);
        }
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <CardBody color="white" as={Stack} direction={"column"}>
        <Flex
          transform={isHover ? "translateY(-2px)" : ""}
          textShadow={isHover ? "-3px 2px 5px #000000" : "none"}
          transition={"0.2s all"}
          align={"center"}
          as={Heading}
          size="md"
          flex={1}
        >
          {product.title}
          {product.href && <Icon as={FaWindowRestore} ml={2} />}
        </Flex>
        <Box
          // textShadow={isHover ? "-3px 2px 5px #000000" : "none"}
          borderLeft={isHover ? "2px solid #ecececaa" : "2px solid #b1b1b1aa"}
          pl={{ base: 0, md: 2 }}
          transition={"0.3s all"}
          flex={2}
          fontSize="0.8em"
          textAlign={"left"}
        >
          {product.caption}
        </Box>
      </CardBody>
    </Card>
  );
};

export default ServiceCard;
