import { Flex, Heading} from '@chakra-ui/react'
import React from 'react'
import CTAButton from '../../components/CTAButton'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
  return (
    <Flex w='full' h='100vh' p={10} justify={'space-evenly'} align='center' direction={'column'}>
      <Heading fontSize='4em' color='white'>Coming Soon</Heading>
      <Heading mt={5} size='md' color='gray.300'>This Page is still in progress, stay tune!</Heading>
      <CTAButton as={Link} to="/">Back to HomePage</CTAButton>
      {/* <Image w='150px' src={comingSoonImg} /> */}
    </Flex>
  )
}

export default ComingSoon