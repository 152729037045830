import { Button, Flex, Heading, Icon, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaBrain, FaComputer, FaGears } from "react-icons/fa6";
import { Link } from "react-router-dom";
import FadeInComponent from "../../../components/FadeInComponent";

const Specialization = () => {
  return (
    <FadeInComponent>
      <Stack
        spacing={{ base: 3, md: "10vw" }}
        px={{ base: 10, md: "10em" }}
        align={"center"}
        minH="100vh"
        h="full"
        w="full"
        direction={{ base: "column", md: "row" }}
      >
        <Flex
          justify={{ base: "center", md: "start" }}
          align="center"
          flex={1}
          fontSize="1.5em"
          fontWeight={600}
          fontFamily={"Gabarito Variable"}
          color="white"
          direction="row"
          wrap="wrap"
          mt={{ base: "5em", md: 0 }}
        >
          We specialize in{" "}
          <Text bgClip={"text"} textShadow={"-1px 1px 20px #2a437f;"} fontSize="1.2em" bgGradient={"linear(to-tr,blue.500,purple.400)"} mx={2}>
            Digitalization
          </Text>{" "}
          and bringing{" "}
          <Text bgClip={"text"} fontSize="1.2em" textShadow={"-1px 1px 20px #2a437f;"} bgGradient={"linear(to-tr,blue.500,purple.400)"} mx={2}>
            AI
          </Text>{" "}
          to fuel your business.
          <Button display="block" mt={4} as={Link} to="/services" variant="link" color="gray.300">
            All Our Products
          </Button>
        </Flex>
        <Flex flex={2}>
          <VStack w="full" spacing={10}>
            <IconView
              className="fadeIn-1"
              icon={FaComputer}
              heading={"Sofware"}
              link="services/cloud-computing"
              details={
                <>
                  We digitalize forms and process flows. <br />
                  You get real-time updates on your business tasks. <br />
                  Know where your bottlenecks are.
                </>
              }
            />
            <IconView
              className="fadeIn-2"
              icon={FaBrain}
              heading={"AI"}
              link="services/ai"
              details={
                <>
                  Take Advantage of the AI Technology. <br />
                  Predict outcomes of expensive calculations at a fraction of the cost and time <br />
                  AI can assist you with Strategy Planning
                </>
              }
            />
            <IconView
              className="fadeIn-3"
              icon={FaGears}
              heading={"Hardware"}
              link="services/hardware"
              details={
                <>
                  Machines are the life-force of a production factory <br />
                  We can provide customized hardware solutions for specific tasks or IOT implementations for real-time production status.
                </>
              }
            />
          </VStack>
        </Flex>
      </Stack>
    </FadeInComponent>
  );
};

const IconView = ({ icon, heading, details, link, className }: any) => {
  return (
    <Flex
      className={className}
      p={5}
      rounded="md"
      backdropFilter={"blur(5px)"}
      _hover={{ boxShadow: "0 5px 15px rgba(145, 92, 182, .4)", transform: "scale(1.02)" }}
      backgroundColor="blackAlpha.300"
      boxShadow={"2px 2px 14px -3px #00000047"}
      transition={"0.4s all"}
      pos="relative"
      w="full"
      align="center"
      justify={"start"}
      direction={{ base: "column", md: "row" }}
    >
      <Link style={{ position: "absolute", width: "100%", height: "100%" }} to={link} />
      <Icon color="blue.400" fontSize="4em" as={icon} />
      <Flex ml={{ base: 0, md: 5 }} direction="column" align="start" justify={"start"}>
        <Heading textShadow={"-1px 1px 20px #2a437f;"} bgClip={"text"} bgGradient={"linear(to-tl,blue.500,purple.400)"} size="lg">
          {heading}
        </Heading>
        <Text display="inline-flex" textAlign={"left"}>
          {details}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Specialization;
