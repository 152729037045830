import { Flex } from "@chakra-ui/react";
import React from "react";

const FadeInComponent = ({ children }: any) => {
  // const [isVisible, setVisible] = React.useState(false);
  // const domRef = React.useRef<HTMLDivElement>(null);
  // React.useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => setVisible(entry.isIntersecting));
  //   });
  //   if (!isVisible) {
  //     if (domRef.current) {
  //       observer.observe(domRef.current);
  //     }
  //   }
  //   return () => observer.unobserve(domRef!.current as any);
  // }, []);

  return (
    <Flex className={`fade-in-section is-visible`} w="full" h="full">
      {children}
    </Flex>
  );
};

export default FadeInComponent;
