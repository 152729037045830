import {
  Box,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  useDisclosure,
  useRadio,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CTAButton from "../../components/CTAButton";
import axios from "axios";
import { apiRoute } from "../../components/dev";
import EmailSentModal from "../../components/EmailSentModal";
import { useSearchParams } from "react-router-dom";

export type BookingForm = {
  name?: string;
  email: string;
  company?: string;
  service?: string;
  remarks?: string;
};

export const handleSubmit = (details: BookingForm) => {
  axios.post(`${apiRoute()}/consultation.php`, details);
};

const Book = () => {
  const [urlParam] = useSearchParams();
  const service = urlParam.get("service");
  const app = urlParam.get("app");
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen } = useDisclosure();
  const [details, setDetails] = useState<BookingForm>({
    name: "",
    email: "",
    company: "",
    service: service ?? "Cloud Computing",
    remarks: app ? `I would like to know more about ${app}` : "",
  });


  const services = ["Cloud Computing", "AI", "Hardware", "Consultation"];

  const { getRadioProps } = useRadioGroup({
    name: "service",
    defaultValue: service ?? "Cloud Computing",
    onChange: (a) => setDetails((prev) => ({ ...prev, service: a })),
  });

  const handleSubmitButton = () => {
    setLoading(true);
    setTimeout(() => {
      onOpen();
    }, 500);
    handleSubmit(details);
  };

  return (
    <VStack w="full" p={10}>
      <Flex>
        <EmailSentModal isOpen={isOpen} onClose={() => {}} />
        <Heading className="fadeIn" color="gray.300">
          Book a{" "}
          <Box fontSize="1.2em" textShadow={"-1px 1px 20px #2a437f;"} bgClip={"text"} bgGradient={"linear(to-t,blue.500,purple.500)"}>
            Free Consultation
          </Box>
        </Heading>
      </Flex>
      <Box w="full" h="full" pos="relative">
        <Card
          boxShadow={"20px -20px 75px 0px #5400ff30, -20px 20px 75px 0px #c214ff1f;"}
          aspectRatio={5}
          position="relative"
          p={6}
          mt={10}
          w="full"
          h="full"
          bgGradient={"radial(gray.200,gray.100)"}
        >
          <CardBody as={VStack} spacing={4} zIndex={1}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                borderColor={"blackAlpha.300"}
                placeholder="Name"
                value={details.name}
                onChange={(e) => setDetails((prev) => ({ ...prev, name: e.target.value }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                borderColor={"blackAlpha.300"}
                placeholder="Email"
                value={details.email}
                onChange={(e) => setDetails((prev) => ({ ...prev, email: e.target.value }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company</FormLabel>
              <Input
                borderColor={"blackAlpha.300"}
                placeholder="Company"
                value={details.company}
                onChange={(e) => setDetails((prev) => ({ ...prev, company: e.target.value }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Service Type</FormLabel>
              <Stack spacing={10} direction={{ base: "column", md: "row" }} justify={{ base: "center", md: "space-evenly" }}>
                {services.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      {value}
                    </RadioCard>
                  );
                })}
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel>Tell Us More</FormLabel>
              <Textarea
                borderColor={"blackAlpha.300"}
                placeholder="Tell Us More"
                value={details.remarks}
                onChange={(e) => setDetails((prev) => ({ ...prev, remarks: e.target.value }))}
              />
            </FormControl>
            <CTAButton
              isLoading={loading}
              onClick={handleSubmitButton}
              isDisabled={!validateEmail(details.email)}
              size="lg"
              width="full"
              h={{ base: "3em", md: "3em" }}
              fontSize={{ base: "1.3em", md: "1em" }}
            >
              {validateEmail(details.email) ? "Book a Consultation" : "A Valid Email is Required!"}
            </CTAButton>
          </CardBody>
        </Card>
      </Box>
    </VStack>
  );
};

export const validateEmail = (email?: string) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1}>
      <input {...input} />
      <Flex
        align={"center"}
        justify={"center"}
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        height={{ base: "70px", md: "3em" }}
        fontSize={{ base: "1.1em", md: "1em" }}
        borderRadius="md"
        transition="0.3s all"
        boxShadow="md"
        _hover={{
          shadow: "lg",
          backgroundColor: "gray.200",
        }}
        _checked={{
          bgGradient: "linear(to-r,purple.800, blue.800)",
          color: "white",
          fontWeight: "bold",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Flex>
    </Box>
  );
}

export default Book;
