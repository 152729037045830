import React, { useCallback, useEffect, useState } from "react";
import logo from "../img/Logo.png";
import { Box, Button, Flex, HStack, IconButton, Image, SimpleGrid, useDisclosure, VStack } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import CTAButton from "../components/CTAButton";
import { FaBars, FaPlus, FaX } from "react-icons/fa6";
import { useGesture } from "@use-gesture/react";

const Navbar = () => {
  const location = useLocation();
  return (
    <>
      <Flex h="100px" display={{ base: "none", md: "flex" }} w="full" py={5} px={10} justify={"space-between"} align="center" direction={"row"}>
        <Flex as={Link} to="/">
          {" "}
          <Image w="200px" src={logo} />
        </Flex>
        <HStack spacing={20} align="center">
          <Button as={Link} to="/services" fontSize="1.1em" color={location.pathname.includes("services") ? "gray.200" : "gray.400"} variant={"link"}>
            Services
          </Button>
          <Button as={Link} to="/about-us" fontSize="1.1em" color={location.pathname.includes("about-us") ? "gray.200" : "gray.400"} variant={"link"}>
            About Us
          </Button>
          <CTAButton mt={0} as={Link} to={generateBook(location.pathname)}>
            Book a Consultation
          </CTAButton>
        </HStack>
      </Flex>

      <MobileNav />
    </>
  );
};

const toTitleCase = (str:string) => {
  return str
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

export const generateBook = (path: string) => {
  if (path.includes("services/")) {
    const service = path.split('/')[2]
    const app = path.split('/')[3] ?? ''

    return `/book?service=${toTitleCase(service === 'ai'? 'AI' : service)}&app=${toTitleCase(app??'')}`
  }  
  else {
    return "/book"
  }
}

const MobileNav = () => {
  const location = useLocation();
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const bind = useGesture({
    onDrag: ({ down, movement: [mx, my] }) => {
      if (!down) {
        if (my < -5) {
          onOpen();
          setIsVisible(true); // Swipe up to show
        } else if (my > 5) {
          onClose();
          setIsVisible(false); // Swipe down to hide
        }
      }
    },
  });

  const handleScroll = useCallback(() => {
    const container = document.querySelector(".App");
    const currentScrollPosition = container?.scrollTop ?? 0;
    const maxScrollPosition = (container?.scrollHeight ?? 0) - (container?.clientHeight ?? 0);

    setScrollPosition(currentScrollPosition);

    if (currentScrollPosition >= maxScrollPosition) {
      setIsVisible(false); // Hide navbar when at the bottom of the scroll
    } else if (Math.abs(currentScrollPosition - prevScrollPosition) > 20 || currentScrollPosition < 1) {
      if (currentScrollPosition > prevScrollPosition && currentScrollPosition > 5) {
        setIsVisible(false); // Hide navbar when scrolling down
      } else {
        setIsVisible(true); // Show navbar when scrolling up
      }
      setPrevScrollPosition(currentScrollPosition);
    }
  }, [prevScrollPosition]);

  useEffect(() => {
    document.querySelector(".App")?.addEventListener("scroll", handleScroll);

    return () => {
      document.querySelector(".App")?.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPosition, scrollPosition, handleScroll]);

  useEffect(() => {
    if (isOpen) {
      onToggle();
    }
  }, [location.pathname]); //eslint-disable-line

  // useEffect(()=>{
  //   if (!isVisible && isOpen) { onToggle()}
  // },[isVisible, isOpen, onToggle]);

  return (
    <Flex
      boxShadow={"0px -1px 20px 7px #6e6e6e2b"}
      {...bind()}
      background={"black"}
      zIndex={10}
      position="absolute"
      bottom={isVisible ? "0px" : "-500px"}
      maxH={isOpen ? "full" : "80px"}
      transition="0.5s all ease-in-out"
      display={{ base: "flex", md: "none" }}
      w="full"
      direction="column"
      px={2}
      pt={4}
      pb={10}
    >
      <Box h="10px" w="50%" borderTop={"2px solid #545454"} pos="absolute" top={"5px"} left={"25%"}></Box>
      <SimpleGrid mb={5} w="full" columns={3}>
        <Flex as={Link} to="/">
          {" "}
          <Image w="auto" maxH="50px" src={logo} />
        </Flex>
        <IconButton
          onClick={onToggle}
          aria-label="Menu"
          icon={isOpen ? <FaX /> : <FaBars />}
          backgroundColor={"blackAlpha.100"}
          rounded="full"
          _active={{ color: "purple.300", backgroundColor: "blackAlpha.400" }}
          _hover={{ color: "purple.300", backgroundColor: "blackAlpha.400" }}
          transition="0.5s all"
          color="purple.800"
        />
        <Flex align="center" justify={"end"}>
          <CTAButton fontSize="1.2em" mt={0} as={Link} to={generateBook(location.pathname)} leftIcon={<FaPlus />}>
            Book
          </CTAButton>
        </Flex>
      </SimpleGrid>
      <VStack spacing={5}>
        <Button as={Link} to="/services" fontSize="1.1em" color={location.pathname.includes("services") ? "gray.200" : "gray.400"} variant={"link"}>
          Services
        </Button>
        <Button as={Link} to="/about-us" fontSize="1.1em" color={location.pathname.includes("about-us") ? "gray.200" : "gray.400"} variant={"link"}>
          About Us
        </Button>
      </VStack>
    </Flex>
  );
};

export default Navbar;
