import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Stack
      backgroundColor={"blackAlpha.800"}
      color="gray.100"
      backdropFilter={"blur(2px)"}
      zIndex={2}
      px={5}
      py={5}
      direction={{ base: "column", md: "row" }}
      align={{ base: "center", md: "center" }}
      justify={{ base: "center", md: "space-between" }}
      w="100vw"
    >
      <Flex textAlign={{ base: "center", md: "left" }} direction="column">
        <Heading size="md">Augment Sdn. Bhd</Heading>
        <Text>Cloud Computing Solution Experts with AI Implementations</Text>
      </Flex>
      <Flex textAlign={{ base: "center", md: "right" }} direction="column">
        <Heading fontSize="1.2em">Contact Us</Heading>
        <Text as={"a"} href="mailto:sales@my-augment.com">
          Email: sales@my-augment.com
        </Text>
      </Flex>
    </Stack>
  );
};

export default Footer;
