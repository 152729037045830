import { Card, CardBody, Flex, Heading, Image } from "@chakra-ui/react";

export type HookType = {
  title: string;
  caption: JSX.Element;
  image : string;
};

const HookCard = (props: HookType & { index: number }) => {
  const { title, caption, index, image } = props;
  const isEven = index % 2 === 0;
  return (
    <Card
      className="hook-card"
      mb={{ base: 0, md: 5 }}
      color="gray.300"
      _hover={{
        transform: { base: "none", md: "translateY(-5px)" },
        shadow: { base: "none", md: "0 0 100px 10px #07169a2b,-10px 10px 4px 0px #00000080" },
      }}
      transition={"0.3s all ease-out"}
      w="full"
      boxShadow={"10px 10px 100px 0px #0b438828"}
      backdropFilter={{ base: "blur(10px)", md: "blur(2px)" }}
      bgColor={{ base: "none", md: "#00000035" }}
      // minW={{ base: "full", md: "23vw" }}
    >
      <CardBody position='relative' minH="200px" align="center" as={Flex} direction={{ base: "column", md: isEven ? "row" : "row-reverse" }}>
        <Image objectFit={'cover'} opacity={0.1} top={0} left={0}  src={image} h='full' w='full' pos={'absolute'}/>
        <Flex
          zIndex={1}
          borderLeft={{ base: 0, md: isEven ? 0 : "2px solid #f6f6f633" }}
          borderRight={{ base: 0, md: isEven ? "2px solid #f6f6f633" : 0 }}
          pr={{ base: 0, md: isEven ? 5 : 0 }}
          pl={{ base: 0, md: isEven ? 0 : 5 }}
          w="full"
          textAlign={{ base: "center", md: isEven ? "left" : "right" }}
          as={Heading}
          flex={1}
        >
          {title}
        </Flex>
        <Flex
          zIndex={1}
          h="full"
          align={"center"}
          ml={{ base: 0, md: isEven ? 5 : 0 }}
          mr={{ base: 0, md: isEven ? 0 : 5 }}
          display="block"
          flex={{ base: 1, md: 3 }}
          textAlign={{ base: "center", md: index % 2 === 0 ? "left" : "right" }}
        >
          {caption}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default HookCard