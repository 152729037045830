import { Box, Heading, Stack, VStack } from "@chakra-ui/react";
import ccService from "../../img/cc_services.jpg";
import aiService from "../../img/ai_services.jpg";
import { Outlet, useLocation } from "react-router-dom";
import ServiceCard from "../../components/ServiceCard";
import highlightProps from "../../components/props/highlightProps";


const services = [
  {
    title: "Cloud Computing",
    href: "/services/cloud-computing",
    image: ccService,
    ctaCaption: "Our Products",
    products: [
      {
        title: "TaskTracker",
        
        caption: (
          <>
            Digitalize complex workflows and <span>boost productivity</span> with <span>real-time</span> updates for your workforce.
          </>
        ),
        href: "/services/cloud-computing/tasktracker",
      },
      {
        title: "ProjectTracker",
        caption: (
          <>
            Track project details and make real-time updates from <span>any location</span> using your mobile device for <span>faster</span>, more
            accurate project management.
          </>
        ),
        // href: "/services/cloud-computing/projecttracker",
      },
      {
        title: "StockTracker",
        caption: (
          <>
            Manage your stock in <span>real-time</span> with barcode scanning for efficient and <span>accurate</span> inventory control.
          </>
        ),
        // href: "/services/cloud-computing/stocktracker",
      },
    ],
    content: (
      <>
        <Box as="span" {...highlightProps} pl={2}>
          Cloud Computing
        </Box>
        offers scalable, on-demand access to computing resources, enhancing
        <Box as="span" {...highlightProps} px={2}>
          efficiency
        </Box>{" "}
        and
        <Box as="span" {...highlightProps} px={2}>
          reducing costs
        </Box>
        making it essential for modern businesses.
      </>
    ),
  },
  {
    title: "AI Computing",
    href: "/services/ai",
    image: aiService,
    ctaCaption: "Our Products",
    content: (
      <>
        Our{" "}
        <Box as="span" {...highlightProps}>
          AI products
        </Box>{" "}
        predict expensive computations and guide{" "}
        <Box as="span" {...highlightProps}>
          business decisions
        </Box>
        , providing valuable insights and enhancing operational efficiency.
      </>
    ),
    products: [
      {
        title: "Computation Prediction",
        caption: (
          <>
            Prediction of Challenging Calculations can be done in a <span>fraction of time</span>, and with a high degree of <span>accuracy</span> /
            consistency
          </>
        ),
        href: "/services/ai/data-predictor"
      },
    ],
  },
  {
    title: "IOT",
    image: aiService,
    href: "/services/hardware",
    ctaCaption: "Our Products",
    content: (
      <>
        <Box as="span" {...highlightProps}>
          IOT
        </Box>{" "}
        and
        <Box as="span" {...highlightProps}>
          Custom Electronics
        </Box>
        designed to improve production and management of assets.
      </>
    ),
    products: [
      {
        title: "Drink Dispenser",
        caption: (
          <>
            Using Prepaid card to allow for drink dispensing <span>directly</span> by customers, allowing for <span>precise amounts</span> to be
            dispensed when needed
          </>
        ),
      },
      {
        title: "Pneumatic Testing",
        caption: (
          <>
            Pneumatic controller to test loading and <span>repetitive</span> stress on a platform, with <span>integrated</span> step counter and max
            steps control.
          </>
        ),
      },
    ],
  },
];

const Service = () => {
  const location = useLocation();

  return (
    <VStack pb={"50px"} mb={"50px"} mt="50px" h="full" w="full" px={{ base: 0, md: 10 }}>
      <Box mb={5} w="fit-content" className={`typewriter tw-${(location.pathname.split("/").pop()?.toUpperCase().replaceAll('-',' ')??"Services").length}`}>
        <Heading letterSpacing={0.3} fontSize={{ base: "2em", md: "5em" }} color="gray.300" as={"h1"}>
          {location.pathname === "/services" ? "Services" : location.pathname.split("/").pop()?.toUpperCase().replaceAll('-',' ')}
        </Heading>
      </Box>
      {location.pathname === "/services" ? (
        <Stack direction={{ base: "column", md: "row" }} p={5} minW="full" h="full" w="full" wrap="wrap" justify={"space-between"}>
          {services.map((service, i) => (
            <ServiceCard {...service} key={i} />
          ))}
        </Stack>
      ) : (
        <Outlet />
      )}
    </VStack>
  );
};


export default Service;
